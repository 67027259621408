/*###########################################################################
// Header
//#########################################################################*/

import { inViewport } from '../utilities/_inViewport'
import { throttle, debounce } from '../utilities/_throttleDebounce'

const header = {
  config: {
    main: 'main',
    header: '.header',
    headerBackdrop: '.header__backdrop',
    headerNav: '.header__nav',
		headerNavExtra: '.header__nav--extra',
		headerNavItem: '.header__nav__item',
    headerMenuBtn: '.header__menu-btn',
    headerSubMenu: '.header__submenu',
    headerSubMenuSingle: '.header__submenu--single',
    headerSubMenuTrigger: '.header__nav__item--sub',
    headerSubMenuBack: '.header__submenu__back',
  },

  elements: {},

  state: {
    isMobile: false,
    dropdownOpen: false
  },

  breakpoint: 1023,

  init() {
    const self = this

    self.elements.header = document.querySelector(self.config.header)

    if (self.elements.header !== null) {
      self.elements.main = document.querySelector(self.config.main)
      self.elements.headerBackdrop = self.elements.header.querySelector(self.config.headerBackdrop)
      self.elements.headerNav = self.elements.header.querySelector(self.config.headerNav)
			self.elements.headerNavExtra = self.elements.header.querySelector(self.config.headerNavExtra)
			self.elements.headerNavItems = self.elements.header.querySelectorAll(self.config.headerNavItem)
      self.elements.headerMenuBtn = self.elements.header.querySelectorAll(self.config.headerMenuBtn)
      self.elements.headerSubMenus = self.elements.header.querySelectorAll(self.config.headerSubMenu)
      self.elements.headerSubMenuSingles = self.elements.header.querySelectorAll(self.config.headerSubMenuSingle)
      self.elements.headerSubMenuTriggers = self.elements.header.querySelectorAll(self.config.headerSubMenuTrigger)

      // Handle scroll
      //
      self.handleScroll()

      // Handle backdrop
      //
      self.handleBackdropClick()

      // Handle menu btn
      //
      self.handleMenuBtn()

      // Handle menu items
      //
      self.handleMenuItems()

      // Handle sub menu singles
      //
      self.handleSubMenuSingles()

      // Set nav height
      //
      const setHeights = () => {
				if (window.innerWidth <= self.breakpoint) {
					self.unsetNavHeight()

          setTimeout(function() {
						self.setNavHeight()
					}, 200)

          self.state.isMobile = true
        } else {
          self.unsetNavHeight()

          if (self.state.isMobile === true) {
            self.resetMenuState()
          }

					self.setSubMenuesAndBackdropHeight()

          self.state.isMobile = false
        }
			}
			setTimeout(function() {
				setHeights()
			}, 50)

      window.addEventListener('resize', debounce(function () {
				setHeights()

        self.handleSubMenuSingles()
      }, 50))

			window.addEventListener('orientationchange', function() {
				window.location.reload()
			})
    }
  },

  handleScroll() {
    const self = this

    let scrollPosition = window.pageYOffset || document.documentElement.scrollTop

    // Initial check
    //
    if (scrollPosition > 20) {
      self.elements.main.classList.add('header--scrolled')
      self.elements.header.classList.add('header--scrolled')
    } else {
      self.elements.main.classList.remove('header--scrolled')
      self.elements.header.classList.remove('header--scrolled')
    }

    setTimeout(function () {
      // Set nav height
      //
      if (window.innerWidth < self.breakpoint) {
        self.setNavHeight()
      } else {
				self.setSubMenuesAndBackdropHeight()
			}
    }, 1000)

    window.addEventListener('scroll', throttle(function () {
      scrollPosition = window.scrollY || document.documentElement.scrollTop

      if (scrollPosition > 20) {
        self.elements.main.classList.add('header--scrolled')
        self.elements.header.classList.add('header--scrolled')
      } else {
        self.elements.main.classList.remove('header--scrolled')
        self.elements.header.classList.remove('header--scrolled')
      }

      setTimeout(function () {
        // Set nav height
        //
        if (window.innerWidth < self.breakpoint) {
          self.setNavHeight()
        } else {
					self.setSubMenuesAndBackdropHeight()
				}
      }, 500)
    }, 150))
  },

	setSubMenuesAndBackdropHeight() {
		const self = this

		self.elements.headerBackdrop.style.height = (window.innerHeight - (self.elements.header.clientHeight)) + "px"

		for (let i = 0; i < self.elements.headerSubMenus.length; i++) {
      const headerSubMenu = self.elements.headerSubMenus[i]

			if (!headerSubMenu.classList.contains('header__submenu--extra')) {
      	headerSubMenu.style.height = (window.innerHeight - (self.elements.header.clientHeight)) + "px"
			}
    }
	},

  setNavHeight() {
    const self = this

    self.elements.headerNav.style.height = (window.innerHeight - (self.elements.header.clientHeight - 5)) + "px"

		if (window.innerHeight > 800) {
			self.elements.headerNavExtra.style.height = (window.innerHeight - (self.elements.header.clientHeight - 5) - self.getNonExtraNavItemHeights()) + "px"
		} else {
			self.elements.headerNavExtra.style.height = ""
		}

    for (let i = 0; i < self.elements.headerSubMenus.length; i++) {
      const headerSubMenu = self.elements.headerSubMenus[i]

			headerSubMenu.style.height = (window.innerHeight - (self.elements.header.clientHeight - 12)) + "px"
    }
  },

  unsetNavHeight() {
    const self = this

    self.elements.headerNav.style.height = ""

		if (window.innerHeight > 600) {
			self.elements.headerNavExtra.style.height = ""
		}

    for (let i = 0; i < self.elements.headerSubMenus.length; i++) {
      const headerSubMenu = self.elements.headerSubMenus[i]

      headerSubMenu.style.height = ""
    }
  },

	getNonExtraNavItemHeights() {
		const self = this

		let collectiveHeight = 0

		for (let i = 0; i < self.elements.headerNavItems.length; i++) {
      const headerNavItem = self.elements.headerNavItems[i]

      if (!headerNavItem.classList.contains('header__nav__item--extra')) {
				collectiveHeight += headerNavItem.clientHeight
			}
    }

		return collectiveHeight
	},

  handleMenuBtn() {
    const self = this

    for (let i = 0; i < self.elements.headerMenuBtn.length; i++) {
      self.elements.headerMenuBtn[i].addEventListener('click', function (e) {
        e.preventDefault()

        if (self.elements.header.classList.contains('header--open')) {
          self.elements.header.classList.remove('header--open')
          self.elements.headerBackdrop.classList.remove('header__backdrop--active')
          window.app.enableScroll()

					// Handle overflow and scrollbar eating into padding bug
					//
					if (self.isScrollable(self.elements.headerNavExtra) && self.elements.headerMenuBtn[i].classList.contains('header__menu-btn--desktop')) {
						self.elements.headerMenuBtn[i].classList.remove('mr-4')
					}

          setTimeout(function () {
						// Reset sub menus
          	//
            self.resetMenuState()

            // Remove potential classes
            //
            self.elements.header.classList.remove('header--sub-open')
            self.elements.headerNav.classList.remove('overflow-hidden')

            // Reset nav scroll
            //
            self.elements.headerNav.scrollTop = 0

            // Reset nav overflow
            //
            self.elements.headerNav.style.overflowY = ''
          }, 150)
        } else {
          self.elements.header.classList.add('header--open')
          self.elements.headerBackdrop.classList.add('header__backdrop--active')
          window.app.disableScroll()

					// Reset sub menus
          //
					for (let i = 0; i < self.elements.headerSubMenuTriggers.length; i++) {
						const headerSubMenuTrigger = self.elements.headerSubMenuTriggers[i]
						const headerSubMenu = headerSubMenuTrigger.nextElementSibling
						headerSubMenuTrigger.classList.remove('header__nav__item--active')
						headerSubMenu.scrollTop = 0
					}

					// Handle overflow and scrollbar eating into padding bug
					//
					if (self.isScrollable(self.elements.headerNavExtra) && self.elements.headerMenuBtn[i].classList.contains('header__menu-btn--desktop')) {
						self.elements.headerMenuBtn[i].classList.add('mr-4')
					}
        }
      })
    }
  },

	isScrollable(element) {
		const self = this

		if (element.scrollHeight > element.clientHeight) {
			return true
		}
	},

  resetMenuState() {
    const self = this

    self.elements.header.classList.remove('header--open')
    window.app.enableScroll()

    // Reset sub menus
    //
    setTimeout(function () {
      for (let i = 0; i < self.elements.headerSubMenuTriggers.length; i++) {
        const headerSubMenuTrigger = self.elements.headerSubMenuTriggers[i]
        const headerSubMenu = headerSubMenuTrigger.nextElementSibling
        headerSubMenu.classList.remove('header__submenu--open')
        headerSubMenu.scrollTop = 0
        self.setSubMenuTabIndexes(headerSubMenu, false)
      }

      // Remove potential classes
      //
      self.elements.header.classList.remove('header--sub-open')
      self.elements.headerNav.classList.remove('overflow-hidden')

      // Reset nav scroll
      //
      self.elements.headerNav.scrollTop = 0

      // Reset dropdowns
      //
      if (self.elements.headerNav.querySelector('.header__nav__item--active') !== null) {
        self.elements.headerNav.querySelector('.header__nav__item--active').classList.remove('header__nav__item--active')
      }
      self.elements.headerBackdrop.classList.remove('header__backdrop--active')

      // Reset nav overflow
      self.elements.headerNav.style.overflowY = ''
    }, 150)
  },

  handleMenuItems() {
    const self = this

    for (let k = 0; k < self.elements.headerSubMenuTriggers.length; k++) {
      const headerSubMenuTrigger = self.elements.headerSubMenuTriggers[k]

      if (headerSubMenuTrigger.nextElementSibling !== null) {
        const headerSubMenu = headerSubMenuTrigger.nextElementSibling
        const headerSubMenuBack = headerSubMenu.querySelector(self.config.headerSubMenuBack)

        headerSubMenuTrigger.addEventListener('keyup', function (e) {
          if (e.key === 'Enter' || e.keyCode === 13) {
            headerSubMenuTrigger.click()
          }
        })

        headerSubMenuTrigger.addEventListener('click', function (e) {
					e.preventDefault()

          // Handle mobile and desktop states
          //
          if (window.innerWidth < self.breakpoint) {
            if (!headerSubMenu.classList.contains('header__submenu--open')) {
              headerSubMenu.classList.add('header__submenu--open')
              self.elements.header.classList.add('header--sub-open')
              self.elements.headerNav.classList.add('overflow-hidden')
              self.elements.headerNav.scrollTop = 0
              headerSubMenu.scrollTop = 0

              setTimeout(function () {
                self.elements.headerNav.style.overflowY = 'hidden'
              }, 500)
            }
          } else {
            // Remove current active dropdown
            //
            if (headerSubMenuTrigger !== self.elements.headerNav.querySelector('.header__nav__item--active')) {
              if (self.elements.headerNav.querySelector('.header__nav__item--active') !== null) {
                self.elements.headerNav.querySelector('.header__nav__item--active').classList.remove('header__nav__item--active')
                self.setSubMenuTabIndexes(headerSubMenu, false)

								if (!headerSubMenuTrigger.parentNode.classList.contains('header__nav--extra')) {
									self.elements.headerBackdrop.classList.remove('header__backdrop--active')
								}
              }
            }

            // Toggle active dropdown
            //
            if (headerSubMenuTrigger.classList.contains('header__nav__item--active')) {
              headerSubMenuTrigger.classList.remove('header__nav__item--active')
              self.setSubMenuTabIndexes(headerSubMenu, false)

							if (!headerSubMenuTrigger.parentNode.classList.contains('header__nav--extra')) {
								self.elements.header.classList.remove('header--sub-open')
								self.elements.headerBackdrop.classList.remove('header__backdrop--active')
								window.app.enableScroll()
							}
            } else {
              headerSubMenuTrigger.classList.add('header__nav__item--active')
              self.elements.headerBackdrop.classList.add('header__backdrop--active')

							if (!headerSubMenuTrigger.parentNode.classList.contains('header__nav--extra')) {
								self.elements.header.classList.add('header--sub-open')
								self.elements.headerBackdrop.classList.add('header__backdrop--active')
								window.app.disableScroll()
							}

              // On opening a dropdown, ensure all submenu tab indexes are reset
              //
              for (let h = 0; h < self.elements.headerSubMenuTriggers.length; h++) {
                const headerSubMenuTrigger = self.elements.headerSubMenuTriggers[h]

                if (headerSubMenuTrigger.nextElementSibling !== null) {
                  const headerSubMenu = headerSubMenuTrigger.nextElementSibling

                  self.setSubMenuTabIndexes(headerSubMenu, false)
                }
              }

              // Set tab indexes
              //
              self.setSubMenuTabIndexes(headerSubMenu, true)
            }
          }
        })

        if (headerSubMenuBack !== null) {
          headerSubMenuBack.addEventListener('click', function () {
            if (window.innerWidth < self.breakpoint) {
              if (headerSubMenu.classList.contains('header__submenu--open')) {
                headerSubMenu.classList.remove('header__submenu--open')
                self.elements.header.classList.remove('header--sub-open')
                self.elements.headerNav.classList.remove('overflow-hidden')
                self.elements.headerNav.scrollTop = 0
                headerSubMenu.scrollTop = 0

                setTimeout(function () {
                  self.elements.headerNav.style.overflowY = ''
                }, 500)
              }
            }
          })
        }
      }
    }
  },

  handleSubMenuSingles() {
    const self = this

    if (self.elements.headerSubMenuSingles !== null && window.innerWidth >= self.breakpoint) {
      for (let i = 0; i < self.elements.headerSubMenuSingles.length; i++) {
        const headerSubMenuSingle = self.elements.headerSubMenuSingles[i]
        const parentNavItem = headerSubMenuSingle.previousElementSibling
        const leftPosition = parentNavItem.getBoundingClientRect().left + (parentNavItem.getBoundingClientRect().width / 2)

        headerSubMenuSingle.style.left = leftPosition + 'px'
      }
    } else {
      for (let i = 0; i < self.elements.headerSubMenuSingles.length; i++) {
        const headerSubMenuSingle = self.elements.headerSubMenuSingles[i]
        headerSubMenuSingle.style.left = ''
      }
    }
  },

  setSubMenuTabIndexes(headerSubMenu, isFocusable) {
    const self = this

    const focusableElements = headerSubMenu.querySelectorAll('a, button')

    for (let i = 0; i < focusableElements.length; i++) {
      const focusableElement = focusableElements[i]

      if (isFocusable === true) {
        focusableElement.tabIndex = '0'
      } else {
        focusableElement.tabIndex = '-1'
      }
    }
  },

  handleBackdropClick() {
    const self = this

    self.elements.headerBackdrop.addEventListener('click', function () {
      if (window.innerWidth > self.breakpoint) {
				self.elements.header.classList.remove('header--open')
        self.elements.header.classList.remove('header--sub-open')
        self.elements.headerBackdrop.classList.remove('header__backdrop--active')
        window.app.enableScroll()

				if (self.elements.headerNav.querySelector('.header__nav__item--active') !== null) {
					self.elements.headerNav.querySelector('.header__nav__item--active').classList.remove('header__nav__item--active')
				}
      }
    })
  }
}

window.addEventListener('load', function () {
  header.init()
})
