/*###########################################################################
// Faqs
//#########################################################################*/

const faqs = {
	config: {
		faqs: '.faqs'
	},

	elements: {},

	init() {
		const self = this

		self.elements.faqs = document.querySelectorAll(self.config.faqs)

		if (self.elements.faqs !== null) {
			for (let i = 0; i < self.elements.faqs.length; i++) {
				const faqs = self.elements.faqs[i]

				self.handleFaqs(faqs)
			}
		}
	},

	handleFaqs(faqs) {
		const self = this

		const faqNavItems = faqs.querySelectorAll('.faq__nav__item')

		for (let i = 0; i < faqNavItems.length; i++) {
			const faqNavItem = faqNavItems[i]

			self.handleFaqNavItem(faqNavItem, faqs)
		}
	},

	handleFaqNavItem(faqNavItem, faqs) {
		const self = this

		const targetTab = faqs.querySelector(faqNavItem.getAttribute('data-faq-tab'))

		faqNavItem.addEventListener('click', function(e) {
			e.preventDefault()

			self.resetFaqTabs(faqs)

			targetTab.classList.add('is-open')
			faqNavItem.classList.add('is-open')

			setTimeout(function() {
				// Trigger a change event for sprig so it updates
				let swapEvent = new Event('faqTabChange')
				window.dispatchEvent(swapEvent)
			}, 25)
		})
	},

	resetFaqTabs(faqs) {
		const faqTabs = faqs.querySelectorAll('.faq__tab')

		for (let i = 0; i < faqTabs.length; i++) {
			const faqTab = faqTabs[i]

			faqTab.classList.remove('is-open')
		}

		const faqNavItems = faqs.querySelectorAll('.faq__nav__item')

		for (let i = 0; i < faqNavItems.length; i++) {
			const faqNavItem = faqNavItems[i]

			faqNavItem.classList.remove('is-open')
		}
	}
}

window.addEventListener('load', function () {
	faqs.init()
})
