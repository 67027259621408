/*###########################################################################
// Hero
//#########################################################################*/

const hero = {
  config: {
    hero: '.hero',
		heroHome: '.heroHome',
		heroProject: '.heroProject'
  },

  elements: {},

  init() {
    const self = this

    self.elements.hero = document.querySelector(self.config.hero)

    if (self.elements.hero !== null) {
			self.handleHero()
    }

		self.elements.heroHome = document.querySelector(self.config.heroHome)

    if (self.elements.heroHome !== null) {
			self.handleHeroHome()
    }

		self.elements.heroProject = document.querySelector(self.config.heroProject)

    if (self.elements.heroProject !== null) {
			self.handleHeroProject()
    }
  },

	handleHero() {
		const self = this

		setTimeout(function() {
			self.elements.hero.classList.add('has-loaded')
		}, 250)
	},

	handleHeroHome() {
		const self = this

		setTimeout(function() {
			self.elements.heroHome.classList.add('has-loaded')
		}, 250)
	},

	handleHeroProject() {
		const self = this

		setTimeout(function() {
			self.elements.heroProject.classList.add('has-loaded')
		}, 250)
	}
}

window.addEventListener('load', function () {
  hero.init()
})
