/*###########################################################################
// Slider Projects
//#########################################################################*/

import Swiper from "swiper"
import { debounce } from "@js/utilities/_throttleDebounce"

const sliderProjects = {
  config: {
    sliderProjects: '.sliderProjects'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderProjects = document.querySelectorAll(self.config.sliderProjects)

    if (self.elements.sliderProjects !== null) {
      for (let i = 0; i < self.elements.sliderProjects.length; i++) {
        const sliderProjects = self.elements.sliderProjects[i]

        self.handleSliderProjects(sliderProjects)
      }
    }
  },

  initSlider(slider) {
    const swiper = new Swiper(slider, {
      slidesPerView: 1.2,
      spaceBetween: 32,
      loop: false,
      autoHeight: false,
      breakpoints: {
        360: {
          slidesPerView: 1.25,
          spaceBetween: 32,
        },
        414: {
          slidesPerView: 1.25,
          spaceBetween: 32,
        },
        520: {
          slidesPerView: 1.5,
          spaceBetween: 32,
        },
        700: {
          slidesPerView: 1.75,
          spaceBetween: 32,
        },
        1024: {
          slidesPerView: 2.75,
          spaceBetween: 40
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      },
    })

		return swiper
  },

  handleSliderProjects(slider) {
    const self = this

    let swiper = self.initSlider(slider)
    let width = window.innerWidth

    window.addEventListener("resize", debounce(function() {
      if (window.innerWidth !== width) {
        swiper.destroy()

        setTimeout(function() {
          swiper = self.initSlider(slider)
        }, 500)

        width = window.innerWidth
      }
    }, 500))
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
		sliderProjects.init()
	}, 500)
})
