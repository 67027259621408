/*###########################################################################
// Logo scroller
//#########################################################################*/

import { throttle } from '../utilities/_throttleDebounce'
import lottie from 'lottie-web'

window.addEventListener('load', function() {
	const logoScroller = document.querySelector('.logoScroller')

	if (logoScroller !== null) {
		// Load lottie
		lottie.loadAnimation({
			container: logoScroller,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			path: '/dist/lottie/logo-scroller.json',
			rendererSettings: {
				// Log events for debugging
				onLoaded: () => console.log('Animation loaded'),
				onError: e => console.error('Error loading animation:', e),
			}
		}).setSpeed(0.5);

		const setLogoScrollerBgHeight = () => {
			const logoScrollerBg = document.querySelector('.logoScroller__bg')
			const logoScrollerBarHeight = logoScroller.querySelector('g').lastElementChild.getBoundingClientRect().height
			logoScrollerBg.style.height = (logoScrollerBarHeight/2 + 2) + 'px'
		}

		setTimeout(function() {
			setLogoScrollerBgHeight()
		}, 500)

		window.addEventListener('resize', throttle(function () {
			setLogoScrollerBgHeight()
		}, 150))
	}
})
