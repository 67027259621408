import tippy from 'tippy.js';


window.addEventListener('load', function() {
	const tippyEls = document.querySelectorAll('.tippy')

	for (let i = 0; i < tippyEls.length; i++) {
		const tippyEl = tippyEls[i]
		const tippyTooltipText = tippyEl.getAttribute('data-tippy-content')

		tippy(tippyEl, {
			content: tippyTooltipText,
		});
	}

})
