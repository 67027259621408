/*###########################################################################
// Tabs
//#########################################################################*/

import { scrollTo } from "../utilities/_scrollTo"

const tabs = {
  config: {
    tabs: '.tabs',
    tabsNavItem: '.tabs__nav__item',
    tabsContentBlock: '.tabs__content__block'
  },

  elements: {
    tabs: null
  },

  breakpoints: {
    desktop: 1024
  },

  init() {
    const self = this

    self.elements.tabs = document.querySelectorAll(self.config.tabs)

    if (self.elements.tabs !== null) {
      for (let i = 0; i < self.elements.tabs.length; i++) {
        const tabs = self.elements.tabs[i]

        self.handleTabs(tabs)
      }
    }
  },

  handleTabs(tabs) {
    const self = this

    const navItems = tabs.querySelectorAll(self.config.tabsNavItem)
    const navSelect = tabs.querySelector(self.config.tabsNavSelect)

    for (let i = 0; i < navItems.length; i++) {
      const navItem = navItems[i]
      const tabTarget = tabs.querySelector(self.config.tabsContentBlock + '--' + parseInt(navItem.getAttribute('data-tab')))

      navItem.addEventListener('click', function () {
        self.resetTabs(tabs)

        navItem.classList.add('is-active')
        tabTarget.classList.add('is-active')

        if (window.innerWidth < self.breakpoints.desktop) {
          const offsetAmount = document.querySelector('.header').clientHeight
          scrollTo(tabs, -offsetAmount)
        }
      })
    }
  },

  resetTabs(tabs) {
    const self = this

    const navItems = tabs.querySelectorAll(self.config.tabsNavItem)
    const contentBlocks = tabs.querySelectorAll(self.config.tabsContentBlock)

    for (let i = 0; i < navItems.length; i++) {
      const navItem = navItems[i]
      navItem.classList.remove('is-active')
    }

    for (let i = 0; i < contentBlocks.length; i++) {
      const contentBlock = contentBlocks[i]
      contentBlock.classList.remove('is-active')
    }
  }
}

window.addEventListener('load', function () {
  tabs.init()
})
