/*###########################################################################
// Lazy images
//#########################################################################*/

const lazyImages = {
	config: {
		lazyImageAnimated: '.lazyImage.lazyImage--animation'
	},

	elements: {},

	init() {
		const self = this

		self.elements.lazyImagesAnimated = document.querySelectorAll(self.config.lazyImageAnimated)

		if (self.elements.lazyImagesAnimated !== null) {
			for (let i = 0; i < self.elements.lazyImagesAnimated.length; i++) {
				const lazyImage = self.elements.lazyImagesAnimated[i]

				self.handleLazyImageAnimated(lazyImage)
			}
		}

		if (typeof htmx !== 'undefined') {
			// After swap re-add event listeners
			//
			htmx.on('htmx:afterSwap', function (event) {
				self.elements.lazyImagesAnimated = document.querySelectorAll(self.config.lazyImageAnimated)

				if (self.elements.lazyImagesAnimated !== null) {
					for (let i = 0; i < self.elements.lazyImagesAnimated.length; i++) {
						const lazyImage = self.elements.lazyImagesAnimated[i]

						self.handleLazyImageAnimated(lazyImage)
					}
				}
			})
		}

		// Listen for modal open and handle any lazy images
		//
		window.addEventListener('modalOpen', function() {
			self.elements.lazyImagesAnimated = document.querySelectorAll(self.config.lazyImageAnimated)

			if (self.elements.lazyImagesAnimated !== null) {
				for (let i = 0; i < self.elements.lazyImagesAnimated.length; i++) {
					const lazyImage = self.elements.lazyImagesAnimated[i]

					self.handleLazyImageAnimated(lazyImage)
				}
			}
		})
	},

	handleLazyImageAnimated(lazyImage) {
		const self = this

		const imgTag = lazyImage.querySelector('img')

		// Watch for on load event
		//
		imgTag.addEventListener('load', function() {
			lazyImage.classList.add('lazyImage--loaded')
		})

		// Or check if already loaded
		//
		if (imgTag.complete) {
			lazyImage.classList.add('lazyImage--loaded')
		}
	}
}

lazyImages.init()

