/*###########################################################################
// Modals
//#########################################################################*/

const modals = {
	initialised: false,

	elements: {
		modalTriggers: null,
	},

	init() {
		const self = this

		self.elements.modalTriggers = document.querySelectorAll('.modal__trigger')

		if (self.elements.modalTriggers !== null) {
			for (let i = 0; i < self.elements.modalTriggers.length; i++) {
				const modalTrigger = self.elements.modalTriggers[i]
				const targetModal = document.querySelector(modalTrigger.getAttribute('data-modal'))

				if (targetModal !== null) {
					modalTrigger.addEventListener('click', function (e) {
						e.preventDefault()

						self.handleOpen(targetModal, modalTrigger)
					})
				} else {
					console.error('Unable to find target modal for modal trigger.')
				}
			}
		}
	},

	handleOpen(modal, modalTrigger) {
		const self = this

		// Open modal
		//
		modal.classList.add('open')

		// Lock body scroll
		//
		document.documentElement.style.overflow = 'hidden'

		// Handle close
		//
		self.handleClose(modal)

		// Handle info modal
		//
		if (modal.classList.contains('modal--dynamic') && modalTrigger.getAttribute('data-modal-content') !== '') {
			self.handleDynamicModalOpen(modal, modalTrigger.getAttribute('data-modal-content'))
		}

		// Emit custom event, mainly so that lazy images can be handled
		//
		let modalOpen = new Event("modalOpen");
		window.dispatchEvent(modalOpen);
	},

	handleClose(modal) {
		const self = this

		const modalClose = modal.querySelector('.modal__close-btn')
		modalClose.addEventListener('click', function (e) {
			self.closeModal(modal)
		})

		const modalOverlay = modal.querySelector('.modal__overlay')
		modalOverlay.addEventListener('click', function (e) {
			self.closeModal(modal)
		})
	},

	closeModal(modal) {
		// Close modal
		//
		modal.classList.remove('open')

		// Unlock scroll
		//
		document.documentElement.style.overflow = ''

		setTimeout(function () {
			// Clear form modal scroll
			//
			modal.scrollTop = 0
		}, 300)
	},

	handleDynamicModalOpen(modal, modalContent) {
		const self = this

		modal.querySelector('.modal__content__inner').innerHTML = JSON.parse(modalContent)
	}
}

window.addEventListener('load', function () {
	setTimeout(function() {
		modals.init()

		if (typeof htmx !== 'undefined') {
			// After swap re-add event listeners for modal triggers
			//
			htmx.on('htmx:afterSwap', function (event) {
				setTimeout(() => {
					modals.init()
				}, 250);
			})
		}
	}, 250)
})
